/* CONTAINER */

.flex {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: -webkit-box;
	display: -moz-box;
	display: flex;
}

.flex_inline {
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: inline-flex;
}

.flex, .flex_inline {
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-moz-box-align: center;
	align-items: center;
}

.flex_v {
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	flex-direction: column;
	-ms-flex-align: stretch;
	-webkit-align-items: stretch;
	-webkit-box-align: stretch;
	-moz-box-align: stretch;
	align-items: stretch;
}

.flex.flex_v {
	height: 100%;
}

/* MULTI LINES */

.flex_wrap {
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

/*	Align */

.flex_align_start {
	-ms-flex-align: start;
	-webkit-align-items: flex-start;
	-webkit-box-align: start;
	-moz-box-align: start;
	align-items: flex-start;
}

.flex_align_end {
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-moz-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.flex_align_center {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.flex_align_baseline {
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-moz-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

.flex_align_stretch {
	-ms-flex-align: stretch;
	-webkit-align-items: stretch;
	-webkit-box-align: stretch;
	-moz-box-align: stretch;
	align-items: stretch;
}

/*	Justify */

.flex_justify_start {
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	-webkit-box-pack: start;
	-moz-box-pack: start;
	justify-content: flex-start;
}

.flex_justify_end {
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	-webkit-box-pack: end;
	-moz-box-pack: end;
	justify-content: flex-end;
}

.flex_justify_center {
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	justify-content: center;
}

.flex_justify_around {
	-ms-flex-pack: distribute;
	-webkit-justify-content: space-around;
	justify-content: space-around;
}

.flex_justify_between {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.flex_justify_evenly {
	-webkit-box-pack: space-evenly;
	-webkit-justify-content: space-evenly;
	-moz-box-pack: space-evenly;
	-ms-flex-pack: space-evenly;
	justify-content: space-evenly;
}

/* ITEMS */

.flex_item_auto {
	-ms-flex: 0 1 auto;
	-webkit-flex: 0 1 auto;
	-webkit-box-flex: 0;
	-moz-box-flex: 0;
	flex: 0 1 auto;
}

.flex_item_full {
	-ms-flex: 1 1 auto;
	-webkit-flex: 1 1 auto;
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	flex: 1 1 auto;
}
.flex_v .flex_item_full {
	min-height: 0;
}

.flex_item_full_fix {
	-ms-flex: 1 0 auto;
	-webkit-flex: 1 0 auto;
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	flex: 1 0 auto;
}

.flex_item_fix {
	-ms-flex: 0 0 auto;
	-webkit-flex: 0 0 auto;
	-webkit-box-flex: 0;
	-moz-box-flex: 0;
	flex: 0 0 auto;
}

.flex_item_overflow {
	overflow-x: hidden;
}

.flex_item_align_start {
	-ms-flex-item-align: start;
	align-self: flex-start;
}