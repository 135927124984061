.headSetup {
    display: flex;
    margin: 15px auto;
    justify-content: space-between;
}

.headSetup div {
    /* width: 240px; */
}

.btn-save-pagetype {
    align-items: flex-end;
}

.container-zoning {
    width: 100%;
}

.screenshotDataContainer {
    display: flex;
    /* width: 100%; */
}

.elementZoning:nth-child(1) {
    /* width: 40%; */
}

.elementZoning:nth-child(2) {
    width: 100%;
}

.number-of-page {
    font-weight: bold;
    font-size: 20px;
}