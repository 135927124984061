.headerBar {
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    background: #F6F7F8;
    padding: 10px 0;
    box-shadow: 0 0 4px 3px #59595947;
}

.search_bar {
    margin: auto;
    max-width: 769px;
}

.search_bar input {
    margin-right: -1px;
}

.search_bar select {}

.selectionCustom:hover {
    cursor: pointer;
}

.title-list-bespoke {
    margin-top: 30px;
    margin-bottom: 30px;
}

.list-bespoke {
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
    display: flex;
    max-width: 769px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
}

.bespoke-item {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px rgba(220, 220, 220, 0.9);
    background: #FFF;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    position: relative;
}

.bespoke-item-img {
    margin-right: 10px;
    width: 170px;
    height: 100px;
    position: relative;
}

.bespoke-item-img img {
    display: block;
    width: 170px;
}

.bespoke-item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bespoke-item-detail {
    height: 100%;
}

.bespoke-item-visibility {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #F6F7F8;
    color: #9E9E9E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5px;
}

.bespoke-item-code {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}

.bespoke-item-code a {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.bespoke-item-code a:hover {
    color: #006fff;
}

.bespoke-item-env {
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 7px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
}

.bespoke-item-name {
    font-weight: 400;
    color: #9E9E9E;
    font-size: 12px;
    margin-left: 10px;
}

.bespoke-item-account {
    font-weight: 400;
    color: #9E9E9E;
    font-size: 12px;
    margin-bottom: 8px;
}

.bespoke-item-description {
    color: #9E9E9E;
    font-size: 12px;
    margin-bottom: 5px;
}

.bespoke-item-key {
    font-size: 12px;
    text-transform: uppercase;
    color: #9E9E9E;
    display: inline-block;
}

.bespoke-item-btn {}

.bespoke-item-btn button {
    border: none;
    color: #9e9e9e;
    border: 1px solid #9e9e9e;
    border-radius: 3px;
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 600;
    background: #f6f7f8;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.bespoke-item-btn button a {}

.bespoke-item-btn .edit-bespoke:hover, .bespoke-item-btn .edit-bespoke:hover a {
    background: #006fff;
    border-color: #006fff;
    color: #FFF;
}

.bespoke-item-btn .delete-bespoke:hover {
    background: #D76666;
    border-color: #D76666;
    color: #FFF;
}

.bespoke-item-errors {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #D76666;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.bespoke-item-errors:hover {
    background: #ba5757;
}

.connection-message {
    display: flex;
    justify-content: center;
}

.connection-message div p {
    font-weight: bold;
    font-size: 18px;
}

.connection-message div button {
    margin: 13px auto;
    display: flex;
}