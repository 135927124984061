.avatar {
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #ccc;
    color: #777;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    user-select: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
}

.avatar.size_s {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
}

.avatar.size_m {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
}

.avatar.size_l {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
}

.avatar.size_xl {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
}

.avatar.size_xxl {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 32px;
}

.avatar.color_red {
    color: #AD0D1A;
    background: #FFB7BC;
}

.avatar.color_orange {
    color: #AF5700;
    background: #FDC893;
}

.avatar.color_yellow {
    color: #A38A00;
    background: #FFF3AF;
}

.avatar.color_green {
    color: #1E7B29;
    background: #C0EEC5;
}

.avatar.color_lightblue {
    color: #025EA0;
    background: #B8DFFB;
}

.avatar.color_blue {
    color: #142FA5;
    background: #B4C1F7;
}

.avatar.color_purple {
    color: #560E81;
    background: #E4C9F5;
}