.script_selection_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
}

.title_editor_code_preview {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    background-color: #212024;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 18px;
}

.title_editor_code_preview p {
    margin-left: 5px;
    margin-right: 5px;
}

.script_parameter_container {
    display: flex;
    position: fixed;
    top: 51px;
    z-index: 1000;
}

.script_parameter_empty {
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    margin-top: 10px;
    margin-top: 35px;
    margin-bottom: 10px;
}