.container {
    width: 100vw;
    height: calc(90vh - 56px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    background-color: rgb(250, 252, 255);
}
.content {
    min-height: 10%;
    height: fit-content!important;;
}
.content {
   font-weight: bold;
   font-size: 19px;
   line-height: 2 ;
}