.title-list-account {
    margin-top: 30px;
    margin-bottom: 30px;
}

.list-account {
    max-width: 80%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px rgba(220, 220, 220, 0.9);
    background: #FFF;
    margin-bottom: 20px;
}

.account-item-env {
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 7px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 20px;
}

.list-account-account {
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
    display: flex;
    max-width: 900px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
}

.account-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px 16px;
    position: relative;
    border-bottom: solid 1px #E9EAF0;
}

.account-item h2 {
    flex: 1;
    font-weight: bold;
}

.account-item button {

    margin-left: 30px;
}

.account-item-btn {
    margin-left: 30px;
    margin-right: 5px;
}

.account-item-btn .edit-account {
    border: none;
    color: #016fff;
    border: 1px solid #016fff;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 600;
    background: #ffffff;
    transition: all 100ms ease-in-out;
    cursor: pointer;

}

.account-item-btn .edit-account:hover {
    background: #006fff;
    border-color: #006fff;
    color: #FFF;
}

.account-item-btn .delete-account:hover {
    background: #D76666;
    border-color: #D76666;
    color: #FFF;
}

.simplify-name {
    font-weight: bold;
    text-align: left;
    width: 110px;
}

.simplify-name:hover {
    color: #006fff;
}

.simplify-status-account {
    font-size: 15px;
    padding: 5px 7px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
}

.list_bespoke_container {
    overflow: auto;
    min-height: 50px;
}

.list_bespoke_empty {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}

.list_bespoke_empty a {
    margin-top: 10px;
}

.list_bespoke_empty a:hover {
    color: white;
}