.page_body{
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto !important;
}

.notifications_list {
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    right: 10px;
    width: 300px;
    top: 225px;
    z-index: 1000;
    overflow: auto;
    max-height: 90vh;
}

.onglets:first-child {
    margin-left: 18px;
}

.notification-message p {
    padding: 5px;
    color: #0f2740;
    font-weight: 400;
}

.languageEditor {
    flex: 1;
    background-color: #000000;
}
.configEditor {
    width: 33%;
    background-color: #000000;
    margin-left: auto;
    margin-left: 5px;
}

.nav {
    padding: 8px 20px;
    border-bottom: solid 1px #E9EAF0;
    min-height: 56px;
}

.nav-controle .btnUi {
    margin-right: 11px;
}

.editor_wrapper{
    position: relative;
    overflow: hidden;
}
.editor_wrapper > section {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
}