.overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 500;
    
}

.contenu {
    width: 800px;
    height: auto;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    height: 400px;
}

.btn-close {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 1000;
}