.container-pagetype-creation {
    display: flex;
    flex-direction: column;
}

.container-btn-pagetype-creation {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: auto;
}

.pagetype-edition-modification-mode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-pagetype-creation h1 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.pagetype-edition-modification-mode-container h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: rgb(100, 100, 100);
}

.pagetype-edition-modification-mode-container ul {
    font-weight: bold;
    text-align: left;
    width: 50%;
    margin: auto;
    height: 300px;
    overflow: auto;
}

.pagetype-edition-modification-mode-container ul li {
    margin: 5px auto;
    cursor: pointer;
}

.pagetype-edition-modification-mode-container ul li:hover {
    color: #67d6d6;
}

.delete-pagetype {
    width: 100%;
    text-align: end;
}

.delete-pagetype i:hover {
    color: red;
    cursor: pointer;
}