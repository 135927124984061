.listbox {
	margin: 0;
}

.listbox ul {
	padding: 0 0 0 5px;
}

.listbox ul.no_indent ul {
	padding-left: 0;
}

.listbox li {
	margin: 0;
	padding: 0;
	position: relative;
	list-style-image: none;
}

/* LABEL */
.listbox .listbox_label {
	padding: .8em 2em .2em 1em;
	color: inherit;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	color: var(--grey-3);
	text-transform: uppercase;
}

/* ITEM */
.listbox .listbox_item {
	display: block;
	border: none;
	font-size: 14px;
	line-height: 1.3em;
	margin: 0;
	color: inherit;
	overflow: hidden;
	word-break: normal;
	white-space: nowrap;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: .5em 2em .5em 1em;
}

.listbox .listbox_item.l {
	padding-top: .7em;
	padding-bottom: .7em;
}

.listbox .listbox_item:not(.disabled) {
	cursor: pointer;
}

.listbox .listbox_item:not(.disabled):hover {
	background-color: #F7F8FA;
	text-decoration: none;
}

.listbox .listbox_item.disabled {
	opacity: .5;
}

.listbox .listbox_item.selected {
	background-color: #F7F8FA;
}

.listbox .listbox_item_right {
	padding-right: 1em;
}

.listbox .listbox_item_icon {
	margin-right: 10px;
	color: var(--grey-3);
}

.listbox.has_border>li+li {
	border-top: solid 1px #EEEFF4;
}

.listbox .listbox_sep {
	margin: 8px 0;
	border-top: solid 1px #E2E4ED;
}

.listbox .listbox_item_desc {
	display: block;
	font-size: 13px;
	color: #5d637e;
	margin-top: 2px;
}

.listbox .listbox_item_check {
	margin-right: -12px;
	margin-left: 16px;
	color: #757b96;
}

/* Smart HR */
.listbox li+.hr+li {
	margin-top: .416em;
	padding-top: .416em;
	border-top: solid 1px #eee;
}

.listbox li.hr:first-child+li,
.listbox li.hr:first-child+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr:first-child+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li.hr+li,
.listbox li.hr {
	margin: 0 !important;
	padding: 0 !important;
	border: 0 !important;
}