.nav {
    
}
.title{
cursor: pointer;
}
.breadcrumb {
    display: flex;
    color: #006FFF;
    font-size: 14px;
    font-weight: 500;
}
.breadcrumbItem {
    margin-right: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.breadcrumbItem:not(:first-child)::before {
    content: "";
    background-image: url(../../Assets/chevron.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}
.campaign {
    color: #424B5C;
    text-transform: unset;
}
.abtest{
    color: #424B5C;
}