@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;600;700');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,300italic,400,400italic,500,700italic,700');


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

html,
body {
	height: 100%;
}
body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	margin: 0;
	padding: 0;
	overflow: visible;
	background-color: #F6F7F8;
	font-family: "Roboto", Arial, sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
	overflow-wrap: break-word;
}

a:hover {
	text-decoration: none;
}

.site-container {
	padding-top: 56px;
	min-height: calc(100vh - 56px);
	box-sizing: content-box;
}

.loaderAcc {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-top: 440px;
	width: 100%;
}

/*//////////////////////// TITLES ///////////////////////////////*/

.title-h1 {
	text-align: center;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 700;
	font-size: 50px;
	color: black;
}

.title-h2 {
	text-align: center;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 700;
	font-size: 40px;
	color: black;
}

.title-h3 {
	text-align: center;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 600;
	font-size: 40px;
	color: black;
}

.title-h4 {
	text-align: center;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-size: 30px;
	font-weight: 400;
	color: black;
}

.title-h5 {
	text-align: center;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-size: 25px;
	font-weight: 400;
	color: black;
}

.title-h5-card {
	text-align: center;
	background-color: #9e9e9e;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-size: 25px;
	font-weight: 400;
	color: black;
}

.title-h3-edit {
	text-align: center;
	margin: 5px;
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 600;
	font-size: 40px;
	color: black;
}

/*///////////////////////  COLORS ///////////////////////////////////// ***/

.blue {
	color: #006FFF;
}

.blue-dark {
	color: #002453
}

.white {
	color: #FFFFFF
}

.gray {
	color: #9e9e9e
}

.black {
	color: #293133
}

.red {
	color: #d76666
}

.pink {
	color: #d767a9
}

.purple {
	color: #7e67d7
}

.green {
	color: #4fd984
}

/*////////////             BTN            /////////////*/

.btn-normal {
	width: 100px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	color: white;
	font-size: 15px;
	font-weight: bold;
	line-height: 1.125;
	border-radius: 14px;
	padding: 2px 5px;
	cursor: pointer;
	text-decoration: none;
}

.btn-normal:hover {
	text-decoration: none;
	color: black;
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 600;
	font-size: 16px;
	font-weight: bold;
	transition: linear 3ms;
	background-color: #ffffffca;
	box-shadow: 0px 2px 15px rgba(172, 172, 172, 0.94);
	border: 2px solid rgb(0, 0, 0);
}

.btn-normal:focus {
	outline: none;
	box-shadow: none;
	text-decoration: none;
}

.btn-next {
	width: 45px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(156, 156, 156);
	background-color: rgba(220, 228, 228, 0.507);
	font-size: 14px;
	line-height: 1.125;
	border-radius: 5px;
	padding: 2px 5px;
	cursor: pointer;
	text-decoration: none;
	margin-left: 75%;
}

.btn-next:hover {
	text-decoration: none;
	color: #ffffff;
	background-color: rgb(228, 228, 228);
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 600;
	transition: linear 3ms;
}

.btn-back {
	width: 45px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(156, 156, 156);
	background-color: rgba(220, 228, 228, 0.507);
	font-size: 14px;
	line-height: 1.125;
	border-radius: 5px;
	padding: 2px 5px;
	cursor: pointer;
	text-decoration: none;
	margin-right: 75%;
	margin: 1% 75% 0% 1%;
}

.btn-back:hover {
	text-decoration: none;
	color: #ffffff;
	background-color: rgb(228, 228, 228);
	font-family: "Roboto", Arial, sans-serif;
	font-weight: 600;
	transition: linear 3ms;
}

.ChooseCopy {
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 61px;
	transition: 200ms;
	font-weight: bold;
}

.ChooseCopy:hover {
	color: rgb(155, 155, 155);
	cursor: pointer;
}

.ChooseCreate {
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 70px;
	transition: 200ms;
}

.ChooseCreate:hover {
	color: rgb(155, 155, 155);
	cursor: pointer;
}

.ChooseModeCreation {
	display: flex;
	flex-direction: column;
}

/* //////////// Icons //////////////// */

.icon-group {
	display: inline-flex;
	flex-direction: row;
	justify-content: space-around;
	/* align-items: center; */
}

.icon-container {
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 28px;
	transition: 200ms;
}

.icon-group:hover>.icon-blue {
	color: #006fff;
	font-size: 20px;
	font-weight: bold;
}

.icon-group:hover>.icon-green {
	color: #72c740;
	cursor: pointer;
	font-weight: bold;
}

.icon-group:hover>.icon-red {
	color: #f32409;
	cursor: pointer;
	font-weight: bold;
}

.icon-group>.icon-redWarning {
	color: #ff1605c2;
	cursor: pointer;
	font-weight: bold;
}

.icon-group:hover>.icon-redWarning {
	color: #ff9408;
	cursor: pointer;
	font-weight: bold;
}

.icon-group:hover>.icon-white {
	color: #e2e2e2;
	cursor: pointer;
	font-weight: bold;
}

.icon-white {
	color: #e2e2e2;
	cursor: pointer;
	font-weight: bold;
}

/* //////////// ENV COLORS //////////////// */

.colorEnvDev {
	color: #FFF;
	background-color: #DDBF44;
}

.colorEnvProd {
	color: #FFF;
	background-color: #67d6d7;
}

.coloEnvPreProd {
	color: #FFF;
	background-color: #4FD984;
}

.container-prop {
	height: 400px;
	overflow: auto;
}

/* ///// Alerte API AXIOS ////////////////////*/

#alertSuccess {
	display: none;
	width: 100%;
	text-align: center;
	position: fixed;
	top: 50px;
	right: 0;
	z-index: 2000;
	margin: auto;
}

#alertBad {
	display: none;
	width: 100%;
	text-align: center;
	position: fixed;
	right: 0;
	z-index: 2000;
	margin: auto;
}

.noData {
	text-align: center;
	font-style: italic;
	font-size: 25px;
	color: #9E9E9E;
}

.noLink:hover {
	text-decoration: none;
}

/* ///// LOADER ///// */

.spinner {
	margin: 0 auto;
	width: 50px;
	height: 60px;
	padding-top: 20px;
	text-align: center;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.spinner.spinner_overlayed {
	position: fixed;
	z-index: 9999;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}

.spinner>div {
	background-color: #006fff;
	height: 100%;
	width: 6px;
	margin: 0 1px;
	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	-moz-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	-o-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	-moz-animation-delay: -1.1s;
	-o-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .rect3 {
	-webkit-animation-delay: -1.0s;
	-moz-animation-delay: -1.0s;
	-o-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	-moz-animation-delay: -0.9s;
	-o-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	-moz-animation-delay: -0.8s;
	-o-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

.spinner_product .spinner_msg {
	text-align: center;
	font-weight: bold;
	font-size: 23px;
}

@-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4)
	}

	20% {
		-webkit-transform: scaleY(1.0)
	}
}

@-moz-keyframes sk-stretchdelay {
	0%, 40%, 100% {
		-moz-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		-moz-transform: scaleY(1.0);
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
}

@-o-keyframes sk-stretchdelay {
	0%, 40%, 100% {
		-o-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		-o-transform: scaleY(1.0);
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		-moz-transform: scaleY(0.4);
		-o-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		-moz-transform: scaleY(1.0);
		-o-transform: scaleY(1.0);
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
}

/* ///// CUSTOM CSS ///// */
.bespoke-detail input,
.bespoke-detail label {
	font-size: 14px;
}
.bespoke-detail-inner {
	padding: 30px;
	font-size: 14px;
}
.bespoke-detail, .bespoke-detail-2 {
	max-width: 650px;
	margin: 0 auto 20px;
}

.bespoke-detail-2 {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.bespoke-detail-2 .bespoke-detail {
	width: calc(50% - 10px);
	margin: 0;
}

.bespoke-detail>label {
	margin-bottom: 5px;
	font-weight: 600;
    color: #151E39;
}

.all_properties {
	height: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0 10px;
}

.bespoke-header {
	padding: 10px;
	text-align: center;
	background: #F6F7F8;
	position: sticky;
	z-index: 1;
	top: 0px;
	box-shadow: 0 2px 4px 0px #59595947;
}

.bespoke-header p {
	display: flex;
	align-items: center;
	justify-content: center;
}

.bespoke-header span {
	text-transform: uppercase;
	font-size: 10px;
	padding: 5px 7px;
	display: inline-block;
	border-radius: 2px;
	margin-right: 10px;
	font-weight: 700;
}

.bespoke-header h3 {
	margin-top: 5px;
	display: block;
	font-weight: 700;
	font-size: 20px;
}

.properties_inner {
	width: calc(50% - 5px);
	display: flex;
	flex-direction: column;
}

.properties_search {
	margin: 10px 0;
}

ul.properties_list {
	height: calc(100% - 110px);
	overflow: scroll;
}

.property-container {
	display: flex;
	align-items: center;
}

.property-container span, .bespoke-property span {
	display: inline-block;
	margin-right: 10px;
	color: #9E9E9E;
	cursor: pointer;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.property-container span:hover, .bespoke-property span:hover {
	color: #006fff;
}

.property-container p {}

.bespoke-property {
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bespoke-property span {
	margin-right: 0;
}

.bespoke-property p {
	font-weight: 700;
}

.select-status {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 5px 7px;
	width: 150px;
}

.select-status label {
	font-weight: bold;
}

.select-status select {
	outline: none !important;
	padding: 9px !important;
	border: 1px solid #DEDEDE;
	cursor: pointer;
	width: 150px;
	margin-top: 3px;
}

.input_text {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 180px;
	height: 60px;
}

.input_text label {
	font-weight: bold;
	margin: 4px auto;
}

.center {
	display: flex;
	margin: auto;
}

.active {
	color: #006fff;
}
input[type=file] {
    left: 236px;
    top: 0;
    opacity: 0;
    position: absolute;
    font-size: 90px;
    width: 120px;
    height: 45px;
    cursor: pointer;
}
/* /// Important for webpack !!! */
iframe#webpack-dev-server-client-overlay{display:none!important}
iframe#webpack-prod-server-client-overlay{display:none!important}