.container {
    width: 100vw;
    height: calc(100vh - 56px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    background-color: rgb(250, 252, 255);
}
.content {
    min-height: 40%;
    height: fit-content!important;;
}