.app_root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}
.app_header {
    flex: 0 0 auto;
}
.app_body {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
}
