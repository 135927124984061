.campaign_status_tag {
    display: inline-block;
    border: solid 1px #E2E4ED;
    border-radius: 50px;
    font-size: 14px;
    line-height: 1.3;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    color: var(--grey-0);
    background: #fff;
    user-select: none;
}

.campaign_status_tag.clickable {
    cursor: pointer;
    transition: all 200ms;
}

.campaign_status_tag.clickable:hover {
    background: #f5f6f8;
    border-color: #DBDCE5;
}

.campaign_status_tag.has_arrow::after {
    margin-left: 5px;
}

.campaign_status_tag_color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 12px;
}

.campaign_status_tag .campaign_status_tag_color {
    margin-right: 8px;
    margin-left: -3px;
}

.campaign_status_tag_color.stopped {
    background-color: #EA6C6C;
}

.campaign_status_tag_color.testing {
    background-color: #6599EB;
}

.campaign_status_tag_color.past {
    background-color: #A6A6A8;
}

.campaign_status_tag_color.planned {
    background-color: #FCCB27;
}

.campaign_status_tag_color.ongoing {
    background-color: #7DC974;
}

.campaign_status_dropdown_header {
    min-width: 280px;
}

.campaign_status_dropdown_header .campaign_status_tag_color {
    align-self: flex-start;
    margin-top: 13px;
}