.status {
    border-radius: 30px;
    padding: 6px 10px 6px 13px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Draft {
    background-color: #CEF4FF;
    color: #00A6D8;
}
.Ongoing {
    background-color: #D1FCCD;
    color: #46AC3A;
}
.Stopped {
    background-color: #FDDBDB;
    color: #EA6C6C;
}
.Scheduled {
    background-color: #FFF6D8;
    color: #EDB600;
}
.Past {
    color: #A6A6A8;
    background-color: #EFEFEF;
}
.Draft svg {
    fill: #00A6D8;
}
.Ongoing svg {
    fill: #46AC3A;
}
.Stopped svg {
    fill: #EA6C6C;
}
.Scheduled svg {
    fill: #EDB600;
}
.Past svg {
    fill: #A6A6A8;
}