.switch-template-type {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    height: 18px;
    width: 150px;
    cursor: pointer;
}

.switch-template-type:hover {
    color: #5d6e6e!important;
}