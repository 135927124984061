.cardContainer {
    padding: 20px 20px 20px 0px;
    position: relative;
    min-height: 90px;
}
.cardContainer::after {
    content: ' ';
    width: calc(100% );
    height: 1px ;
    position: absolute;
    bottom: 0px;
    background-color: #f7faff;
}
.content {
    padding-left: 20px;
    position: relative;
}
.header{
    display: flex;
}
.title {
    flex: 4;
    font-size: 14px;
    color: #151E39;
    font-weight: 600;
    margin-bottom: 12px;
}
.cardContainer:hover{
    background-color: #f7faff;
    cursor: pointer;
}
.cardContainer.selected .title {
    color: #006FFF;
}
.selected .content::before {
    position: absolute;
    content: '';
    width: 6px;
    height: 100%;
    background-color:  #006FFF;
    bottom: 0;
    top: 0;
    left: -3px;
    border-radius: 5px;
   
}

.description {
    font-size: 13px;
    color: #6E7284;
    line-height: 17px;
}
.status {
    flex: 1;
    border-radius: 30px;
    padding: 6px 10px 6px 13px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Draft {
    background-color: #CEF4FF;
    color: #00A6D8;
}
.Ongoing {
    background-color: #D1FCCD;
    color: #46AC3A;
}
.Stopped {
    background-color: #FDDBDB;
    color: #EA6C6C;
}
.Scheduled {
    background-color: #FFF6D8;
    color: #EDB600;
}
.Past {
    color: #A6A6A8;
    background-color: #EFEFEF;
}
.Draft svg {
    fill: #00A6D8;
}
.Ongoing svg {
    fill: #46AC3A;
}
.Stopped svg {
    fill: #EA6C6C;
}
.Scheduled svg {
    fill: #EDB600;
}
.Past svg {
    fill: #A6A6A8;
}