.userAuth {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
    padding-left: 16px;
    border-left: solid 1px #444;
}
.userAuthBtn{
    cursor: pointer;
}
.userAuthBtn:hover{
    background: rgba(255,255,255,.2);
    border-radius: 38px;
    padding-right: 12px;
    margin-right: -12px;
}
.userAuthName{
    margin-left: 10px;
    font-size: 14px;
}
.userAuthImg{
    width: 38px;
    height: auto;
    border-radius: 100%;
}