/* Margin */
.ml_3{	margin-left:3px !important;}
.ml_5{	margin-left:5px !important;}
.ml_8{	margin-left:8px !important;}
.ml_10{	margin-left:10px !important;}
.ml_15{	margin-left:15px !important;}
.ml_20{	margin-left:20px !important;}
.ml_30{	margin-left:30px !important;}

.mr_3{	margin-right:3px !important;}
.mr_5{	margin-right:5px !important;}
.mr_8{	margin-right:8px !important;}
.mr_10{	margin-right:10px !important;}
.mr_15{	margin-right:15px !important;}
.mr_20{	margin-right:20px !important;}
.mr_30{	margin-right:30px !important;}

.mt_3{	margin-top:3px !important;}
.mt_5{	margin-top:5px !important;}
.mt_8{	margin-top:8px !important;}
.mt_10{	margin-top:10px !important;}
.mt_15{	margin-top:15px !important;}
.mt_20{	margin-top:20px !important;}
.mt_25{	margin-top:25px !important;}
.mt_30{	margin-top:30px !important;}
.mt_40{	margin-top:40px !important;}

.mb_3{	margin-bottom:3px !important;}
.mb_5{	margin-bottom:5px !important;}
.mb_8{	margin-bottom:8px !important;}
.mb_10{	margin-bottom:10px !important;}
.mb_15{	margin-bottom:15px !important;}
.mb_20{	margin-bottom:20px !important;}
.mb_30{	margin-bottom:30px !important;}
.mb_40{	margin-bottom:40px !important;}

.no_margin{
	margin: 0 !important;
}

/* padding */
.pl_3{	padding-left:3px !important;}
.pl_5{	padding-left:5px !important;}
.pl_8{	padding-left:8px !important;}
.pl_10{	padding-left:10px !important;}
.pl_15{	padding-left:15px !important;}
.pl_20{	padding-left:20px !important;}
.pl_30{	padding-left:30px !important;}

.pr_3{	padding-right:3px !important;}
.pr_5{	padding-right:5px !important;}
.pr_8{	padding-right:8px !important;}
.pr_10{	padding-right:10px !important;}
.pr_15{	padding-right:15px !important;}
.pr_20{	padding-right:20px !important;}
.pr_30{	padding-right:30px !important;}

.pt_3{	padding-top:3px !important;}
.pt_5{	padding-top:5px !important;}
.pt_8{	padding-top:8px !important;}
.pt_10{	padding-top:10px !important;}
.pt_15{	padding-top:15px !important;}
.pt_20{	padding-top:20px !important;}
.pt_25{	padding-top:25px !important;}
.pt_30{	padding-top:30px !important;}
.pt_40{	padding-top:40px !important;}

.pb_3{	padding-bottom:3px !important;}
.pb_5{	padding-bottom:5px !important;}
.pb_8{	padding-bottom:8px !important;}
.pb_10{	padding-bottom:10px !important;}
.pb_15{	padding-bottom:15px !important;}
.pb_20{	padding-bottom:20px !important;}
.pb_30{	padding-bottom:30px !important;}
.pb_40{	padding-bottom:40px !important;}

.no_padding{
	padding: 0 !important;
}
.v_al_middle{
	vertical-align: middle !important;
}
.v_al_bl{
	vertical-align: baseline !important;
}

.al_left{
	text-align: left !important;
}
.al_right{
	text-align: right !important;
}
.al_center{
	text-align: center !important;
}
.underline{
	text-decoration: underline;
}

.invisible{
	visibility: hidden;
}
.pointer {
	cursor: pointer;
}