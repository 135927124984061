.input_copy_container .code_is_Copy {
    color: #006fff;
}


.form_block_label{
	display: block;
	font-weight: 500;
	margin-bottom: 3px;
}

.form_block_description{
	font-size: 14px;
	color: var(--grey-1);
	margin-bottom: 8px;
	margin-top: -2px;
}

input[type=text].full{
	width: 100%;
}

.form_footer{
	margin-top: 32px;
}
