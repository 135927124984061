/* ASIDE NAVIGATION */

i {
    width: 20px;
    text-align: center;
}

.navigation {
    position: relative;
    top: 0;
    left: 0;
    width: 180px;
    height: 1800px;
    -webkit-transition: left .3s ease-in-out;
    -moz-transition: left .3s ease-in-out;
    -o-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
    background: #f8f8f8;
    display: flex;
    border-right: 1px solid gray;
    flex-direction: column;
}

.navigation li {
    margin: 8px auto;
}

.pageTypeActive {
    color: #006fff;
    background-color: #eeeff1;
    border-left: 10px solid #006fff;
}

.navLink {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    height: 50px;
    display: flex;
    align-items: center;
}

.navLink:hover {
    cursor: pointer;
}

.navLink div {
    margin-left: 15px;
    padding: 10px;
}

.pageType-hide-menu {
    display: none;
}