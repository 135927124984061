.addZone {
    display: flex;
    justify-content: space-between;
    text-align: right;
    font-size: 18px;
    border-bottom: 1px solid #CCCCCC;
}

.delete-zone {
    cursor: pointer;
    color: #006fff;
    font-size: 16px;
    font-weight: bold;
}

.chevron i {
    margin-left: 10px;
}

.isActiveLink {
    font-weight: bold;
}

.name-zone-chevron {
    margin-left: 10px;
}

.addBtn {
    cursor: pointer;
    width: 100px;
    height: 25px;
    color: #006fff;
    font-size: 16px;
    font-weight: bold;
}

.containerFormZone {
    /* height: 320px; */
    background-color: #eeeff1;
    display: none;
}

.displayFormZone {
    display: block;
}

.HeaderZonesActionActive {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede66;
}

.HeaderZonesAction {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.containerFormZone form {
    padding: 15px;
}

.displayFormZone .monaco-editor {
    width: auto !important;
    height: 150px !important;
}

.container-zone-edition {
    padding: 15px;
}

.input-goupe-zone {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 15px;
}

.input-goupe-zone label {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}

.input-text-name-zone {
    outline: none !important;
    padding: 10px !important;
    border: 1px solid #DEDEDE;
    width: 283px;
}

.input-text-name-zone:focus {
    border: 1px solid rgb(103, 214, 215);
}

.input-groupe-size-zone {
    display: flex;
    margin-left: 10px;
}

.input-groupe-container-size-zone {
    display: block;
    margin-left: 10px;
    width: 120px;
}

.input-groupe-size-zone label {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.input-groupe-size-zone-number {
    margin-top: 2px;
    width: 94px;
    outline: none !important;
    padding: 10px !important;
    border: 1px solid #DEDEDE;
}

.selectorLanguageZone label {
    cursor: pointer !important;
}

.titleSection {
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    margin: 10px auto
}

.groupSlider {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

.groupSlider label {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.sliderOptions {
    display: flex;
    width: 100%;
}