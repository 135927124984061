.cb_label {
	vertical-align: middle;
	margin-top: 4px;
	margin-bottom: 4px;
	font-weight: inherit;
	user-select: none;
}
.cb_label.l{
	margin-top: 6px;
	margin-bottom: 6px;
}
.cb_label.xl{
	margin-top: 8px;
	margin-bottom: 8px;
}

.cb_label+.cb_label {
	margin-left: 20px;
}

.cb_label input {
	position: absolute;
	visibility: hidden;
}

.cb_label .cb_label_icon {
	width: 20px;
	height: 20px;
	margin-right: 8px;
	background: url(../../Assets/checkbox.svg);
}

.cb_label .cb_label_text {
	line-height: 20px;
}

.cb_label .cb_label_text .cb_label_tooltip_target {
	color: #acafc0;
	font-size: 13px;
	margin-left: 2px;
}

/* Checkbox */
.cb_label input[type=checkbox]+.cb_label_icon {
	background-position: 0 0;
}

.cb_label:hover input[type=checkbox]+.cb_label_icon {
	background-position: -20px 0;
}

.cb_label input[type=checkbox]:checked+.cb_label_icon {
	background-position: -40px 0;
}

/* Radio */
.cb_label input[type=radio]+.cb_label_icon {
	background-position: 0 -20px;
}

.cb_label:hover input[type=radio]+.cb_label_icon {
	background-position: -20px -20px;
}

.cb_label input[type=radio]:checked+.cb_label_icon {
	background-position: -40px -20px;
}

/* Switch */
.cb_label .cb_label_switch {
	position: relative;
	width: 32px;
	height: 16px;
	border-radius: 20px;
	background: #CBCDD5;
	transition: all .2s ease-in-out;
}
.cb_label .cb_label_switch + .cb_label_text{
	margin-left: 8px;
}
.cb_label .cb_label_switch::before {
	content: "";
	position: absolute;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #fff;
	top: 2px;
	left: 2px;
	transition: all .2s ease-in-out;
}
.cb_label:hover input[type=checkbox]+.cb_label_switch {
	background: #B3B5BF;
}
.cb_label input[type=checkbox]:checked+.cb_label_switch {
	background: #006FFF;
}
.cb_label input[type=checkbox]:checked+.cb_label_switch::before {
	left: 18px;
}
.cb_label.disabled{
	cursor: default;
	pointer-events: none;
	opacity: .55;
}
.cb_label.disabled input[type=checkbox]+.cb_label_switch {
	cursor: default;
}

.cb_label_desc{
	margin-left: 48px;
	font-size: 13px;
	color: #777;
}

/* Size */
.cb_label .cb_label_switch.s {
	width: 32px;
	height: 18px;
}
.cb_label .cb_label_switch.s::before {
	width: 14px;
	height: 14px;
}
.cb_label input[type=checkbox]:checked+.cb_label_switch.s::before {
	left: 16px;
}   