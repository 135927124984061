.notification-container {
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    color: #ffffff;
    font-size: 17px;
}

.notification-container h4 {
    font-weight: bold;
    margin: 4px;
}

.header-notification {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
}

.header-notification i {
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.header-notification i:hover {
    color: rgb(255, 21, 21);
}

.notif-infos {
    background-color: #006fff;
}

.notif-success {
    background-color: #51a351;
}

.notif-danger {
    background-color: #ff2a2a;
}

.notif-danger p {
    color: #fff;
}

.notif-error {
    background-color: #bd362f;
}