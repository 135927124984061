.pagetype-form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    min-height: 100px;
    margin: auto;
    padding: 15px;
}

.input-goupe-pagetype {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 10px;
}

.input-goupe-pagetype label {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}

.input-text {
    outline: none !important;
    padding: 10px !important;
    border: 1px solid #DEDEDE;
    width: 283px;
}

.input-text:focus {
    border: 1px solid rgb(103, 214, 215);
}

.select-tag {
    outline: none !important;
    padding: 9px !important;
    border: 1px solid #DEDEDE;
    cursor: pointer;
    width: 280px;
}