.thumbnailContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    height: auto;
    padding: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

.btn-style{
    position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	line-height: 20px;
    height: 40px;
	max-width: 100%;
	padding: 8px 10px;
	border: solid 1px transparent;
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	border-radius: 6px;
	cursor: pointer;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 100ms ease-in-out;
	-moz-transition: all 100ms ease-in-out;
	-ms-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
    color: #FFFFFF;
    background: #006fff;
}
.thumbnailContainer input[type=file] {
    left: 236px;
    top: 0;
    opacity: 0;
    position: absolute;
    font-size: 90px;
    width: 120px;
    height: 45px;
    cursor: pointer;
}

.upload {
    cursor: pointer;
    width: 100px;
    height: 30px;
    color: #006fff;
    font-size: 16px;
    font-weight: bold;
}

.thumbnailContainer img {
    border: 1px solid #CCCCCC;
    width: auto;
    max-width: 90%;
    max-height: 220px;
    margin: 10px 3px 2px 0px;
}