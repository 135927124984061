.sidebar_container {
    background: #F7F8FA;
}
.sidebar{
    width: 103px;
    z-index: 2;
    overflow: auto;
}
.sidebar_campaign_information {
    border-left: 1px solid #E9EAF0;
    overflow: auto;
}
.items {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #E9EAF0;
    font-size: 12px;
    color: #5D606F;
    padding: 16px 12px;
    text-align: center;
}
.items:hover,
.isOpen {
    cursor: pointer;
    color: #006FFF;
}
.icons {
    margin-bottom: 8px;
}
.items:hover svg,
.isOpen svg {
    fill: #006FFF;
}
.title {
    margin-bottom: 8px;
    font-weight: 500;
    color: #151E39;
}
.items:hover .title {
    color: #151E39;
}
.device:hover,
.responsive:hover {
    cursor: auto;
}
.device_container {
    width: 50px;
    background-color: FFFFFF;
    border-radius: 5px;
    cursor: pointer;
}
.device_items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background-color: #FFFFFF;
    border: 1px solid #CAD0DB;
}
.device_items + .device_items {
    margin-top: -1px;
}
.device_items.disabled {
    background-color: unset;
    cursor: not-allowed;
}
.device_items .icons {
    margin-bottom: unset;
}
.device_items.selected{
    background-color: #006FFF;
    border-color: #006FFF;
    position: relative;
}
.device_items.selected svg,
.items:hover  .device_items.selected svg {
    fill: #FFFFFF;
}
.items:hover .device_container svg {
    fill: #5D606F;
}
.device_items:not(last-child) {
    border-bottom: 1px solid #CAD0DB;
}
.device_items:first-child {
    border-radius: 5px 5px 0 0;
}
.device_items:last-child {
    border-radius: 0 0 5px 5px;
}