.deleteProperty:hover {
    cursor: pointer;
}

.titleOfConfig {
    color: black;
    background-color: #f8f9fa;
    border-bottom: 1px solid rgb(209, 202, 202);
}

.close {
    position: absolute;
    top: 2%;
    right: 11px;
}

.list-information-property h3 {
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
}

.list-item-property {
    text-align: left;
    padding: 3px;
    margin: 2px auto;
}

.list-item-property svg {
    font-size: 20px;
}

.list-item-property svg:hover {
    color: #64c0c2;
    cursor: pointer;
}

.list-known-values {
    display: flex;
    flex-direction: column;
}

.container_edition_json{
    width: 100%;
    display: flex;
    flex-direction: column;
}