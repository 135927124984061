.input_select_container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
}

.input_select_container label {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}

.input-text {
    outline: none !important;
    padding: 10px !important;
    border: 1px solid #DEDEDE;
    width: 283px;
}

.input-text:focus {
    border: 1px solid rgb(103, 214, 215);
}

.select-component {
    outline: none;
    padding: 9px;
    border: 1px solid #DEDEDE;
    cursor: pointer;
}

.select-component_small {
    width: 30%;
}

.select-component_medium {
    width: 60%;
}

.select-component_xl {
    width: 90%;
}