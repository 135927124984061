.modal_outer {
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    outline: none;
    background: rgba(0, 0, 0, .4);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    align-items: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    justify-content: center;
}

.modal_outer:not(.is_invisible) {
    animation: modal 100ms ease-out;
}

.modal_outer.is_invisible {
    opacity: 0;
    pointer-events: none;
}

.modal_inner {
    position: relative;
    background: #fff;
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, .1) 0 0 20px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
}

.modal_close {
    position: absolute;
    top: 2px;
    right: 0;
    padding: 16px;
    cursor: pointer;
    font-size: 20px;
    color: #9E9E9E;
    transition: color 200ms;
}

.modal_close:hover, .modal_close:focus {
    color: inherit;
}

.modal_close .fas {
    display: block;
}

.modal_header, .modal_footer {
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    flex: 0 0 auto;
}

.modal_header {
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
}

.modal_header.has_border {
    border-bottom: solid 1px #eee;
}

.modal_header_desc {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 300;
    color: #9E9E9E;
}

.modal_header_campaign {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.modal_body {
    margin: 24px 0;
    padding: 0 30px;
    overflow-y: auto;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    flex: 1 1 auto;
}

.modal_section {
    padding: 24px 30px;
}

.modal_section_top {
    padding: 24px 30px 0;
}

.modal_section_bottom {
    padding: 0 30px 24px;
}

.modal_section_s {
    padding: 14px 30px;
}

.modal_section_grey {
    background: #F5F5F5;
    border: solid 1px #EEE;
    border-width: 1px 0;
}

.modal_footer {
    padding: 0 30px 30px;
}

.modal_footer_center {
    text-align: center;
}

@keyframes modal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}