.containerConfirmModalDelete {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75); 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.contenuModalDelete {
    position: relative;
    width: 400px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 5px;
    border-top: 10px solid #D76666;
    text-align: center;
    box-shadow: 0px 1px 7px rgba(15, 15, 15, 0.24);
}

.contenuModalDeleteAcc {
    position: relative;
    top: 0;
    left: 0;
    width: 357px;
    padding: 20px 20px 30px;
    background: #FFFFFF;
    border-radius: 5px;
    border-top: 10px solid #D76666;
    text-align: center;
    box-shadow: 0px 1px 7px rgba(15, 15, 15, 0.24);
}

.textModal {
    margin: 7% auto 7% auto;
}

.btnGroup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-closeModalBespokeDelete {
    position: absolute;
    bottom: calc(100% + 20px);
    right: 0;
    color: #FFF;
    cursor: pointer;
}

.btnCancelModal {
    top: 308px;
    left: 447px;
    width: 148px;
    height: 40px;
    background: #F6F7F8;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: #9E9E9E;
    padding: 10px;
}

.btnDeleteModal {
    top: 308px;
    left: 611px;
    width: 126px;
    height: 40px;
    background: #D76666;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: white;
    padding: 10px;
    margin-left: 10px;
}