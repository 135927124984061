.deleteProperty:hover {
    cursor: pointer;
}

.titleOfConfig {
    color: black;
    background-color: #e8e8e8;
    border-bottom: 1px solid rgb(209, 202, 202);
}

.close {
    position: absolute;
    top: 2%;
    right: 11px;
}

.uploadLogoAccount {
    margin-top: 50px;
    margin-right: 5px;
    font-size: 25px;
}

.uploadLogoAccount:hover {
    color: rgb(4, 209, 55);
    cursor: pointer;
}

.gallery-wrapper{
    background: #FFF;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.gallery-header{
    background: #293133;
    color: #FFF;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 10px 10px;
    position: relative;
}
.gallery-title{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    position: relative;
}
.gallery-close{
    position: absolute;
    top: 50%;
    right: 0;
    height: 30px;
    width: 40px;
    color: #FFF;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%); 
}
.gallery-upload-icon{
    border: 1px solid #FFF;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.gallery-upload-icon:hover{
    background: #FFF;
    color: #293133;
}
.gallery-upload-icon span{
    display: inline-block;
    margin-left: 10px;
}
.gallery-search{
    padding: 10px;
}
.gallery-search-input{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.gallery-container{
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.gallery-container.dark {
    background-color: #293133;
}
.gallery-img{
    margin: 10px;
    position: relative;
}
.gallery-img img{
    margin: auto;
    display: block;
}
.gallery-copy-btn{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-transform: uppercase;
    font-weight: 700;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.gallery-copy-btn:hover{
    opacity: 1;
}
.gallery-copy-success{
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background: #2fc532;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 0;
}