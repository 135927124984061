.ob_page_center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 20px 120px;
}
.ob_page_center_inner{
  text-align: center;
}
.ob_logo {
  margin: 0 auto 34px;
  width: 120px;
}
.ob_logo > svg{
  display: block;
  width: 100%;
  height: auto;
}

.ob_title_1 {
  font-size: 35px;
  color: var(--black);
  font-weight: bold;
  border-bottom: none;
  margin-bottom: 16px;
}
.ob_title_2 {
  font-size: 25px;  
  color: var(--black);
  font-weight: bold;
  border-bottom: none;
  margin-bottom: 12px;
}
.ob_title_3 {
  font-size: 20px;
  color: var(--grey-1);
  margin-bottom: 12px;
}
.ob_btn_wrapper{
  margin-top: 40px;
}




.admonition{
    width: 400px;
    border-left-width: 5px;
    border-radius: 0.4rem;
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.1);
    padding: 18px;
}
.admonition-heading{
    display: flex;

}
.admonition-heading h5 {
    flex: 1;
    display: inline-block;
    margin-right: 0.4em;
    vertical-align: middle;
    font-weight: bold;

}
.admonition-icon svg {
    stroke-width: 0;
    display: inline-block;
    height: 22px;
    width: 22px;
}
.admonition-heading h2 {
    flex: 4;
    font-weight: bold;
}
.admonition-content p {
    margin-top: 15px;
}
.success{
   color: #009400;
   border-color: #148414;
   background-color: #bbffbb;
}
.alert{
   color: #ffffff;
   border-color: #fc2d2d;
   background-color: #ff5863;
}
