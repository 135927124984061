.nav_container {
    display: flex;
    color: #006FFF;
    font-size: 14px;
    font-weight: 500;
    padding: 20px;
}

.breadcrumbItem {
    margin: 0 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.breadcrumbItem:first-child {
    margin-left: 0px;
}

.breadcrumbItem:not(:first-child)::before {
    background-image: url(../../../Assets/chevron.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -20px;
    content: " ";
}

.search_container {
    padding: 8px 20px;
    background-color: #F7F8FA;
}

.search_results {
    height: calc(100vh - 56px);
    overflow-y: auto;
}