input[type=checkbox] {
    display: none;
}

.labelOfInput {
    display: block;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background-color: rgb(209, 15, 15);
    border-radius: 25px;
    transition: all .4s ease-out;
}

.labelOfInput::after {
    content: '';
    display: block;
    position: relative;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    transition: all .4s ease-out;
}

input:checked+label {
    background: rgba(22, 194, 36, 0.952);
}

input:checked+.labelOfInput::after {
    transform: translate(20px, 0);
    background: azure;
}