.search_bar {
    display: inline-flex;
    align-items: center;
    width: 240px;
    max-width: 100%;
}
.search_bar.full_w{
    display: flex;
    width: 100%;
}
.search_bar i {
    color: #6E7284;
    margin-right: 9px;
}

/* Bottom border version */
.search_bar_bottom_border{
    border-bottom: 1px solid #EDEDED;
}
.search_bar_bottom_border input[type="text"] {
    width: 100%;
    border: none;
    font-size: 14px;
}
.search_bar_bottom_border input[type="text"]::-webkit-input-placeholder {
    color: #757B96;
    font-weight: 400;
    font-size: 14px;
}

/* Grey version */
.search_bar_grey{
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    width: 92%;
    padding: 8px 0px 8px 12px;
}
.search_bar_grey input[type="text"]{
    background: transparent;
    font-size: inherit;
    border: none;
    position: relative;
    outline: none;
    width: 100%;
}

/* Default version */
.search_bar.custom_input{
}
.search_bar.custom_input i.search_bar_icon{
    font-size: 13px;
    margin-left: 10px;
    margin-right: -3px;
    position: absolute;
    left: 50;
}
