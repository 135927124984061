.btnUi {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	line-height: 20px;
	min-height: 20px;
	max-width: 100%;
	padding: 8px 10px;
	border: solid 1px transparent;
	font-family: "Roboto", sans-serif;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	border-radius: 6px;
	cursor: pointer;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: all 100ms ease-in-out;
	-moz-transition: all 100ms ease-in-out;
	-ms-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
}

.btnUi.w_full {
	display: flex;
	width: 100%;
}

.btnUi.h_full {
	height: 100%;
}

.btnUi.btn_strong {
	font-weight: 600;
}

.btnUi.btn_light {
	font-weight: 400;
}


.btnUi.btn_disabled,
.btnUi.btn_disabled:hover {
	cursor: not-allowed;
}

.btn_group,
.btn_group_l,
.btn_wrapper {
	display: inline-block;
}

.btnUi+.btnUi,
.btn_switch+.btnUi {
	margin-left: 8px;
}

.btn_group .btnUi:not(:first-child),
.btn_group .btn_switch:not(:first-child),
.btn_group .btn_wrapper:not(:first-child) {
	margin-left: 8px;
}

.btn_group_l .btnUi:not(:first-child),
.btn_group_l .btn_switch:not(:first-child),
.btn_group_l .btn_wrapper:not(:first-child) {
	margin-left: 12px;
}

.btn_switch {
	display: inline-flex;
	vertical-align: middle;
}

.btn_switch .btnUi {
	margin-left: 0;
}

.btn_switch .btnUi:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin-left: -1px;
}

.btn_switch .btnUi:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn_switch .btnUi.active {
	position: relative;
	z-index: 1;
}

.btn_switch .btnUi .btn_txt {
	min-width: unset !important;
}

.btn_switch.w_full .btnUi {
	width: 100%;
	flex: 1 1 auto;
	white-space: nowrap;
}

.btn_txt {
	margin: 0 4px;
	flex: 0 1 auto;
}

.btnUi:not(.has_icon) .btn_txt {
	min-width: 44px;
}

.btnUi.btn_no_min_width .btn_txt {
	min-width: unset;
}

.btnUi .fas, .btnUi .fa {
	margin: 0 4px;
	flex: 0 0 auto;
	font-size: 16px;
	line-height: 20px;
}

/* Rounded */
.btn_rounded {
	border-radius: 100px;
}

/* Size */
.btn_xxs {
	padding: 3px 8px;
	font-size: 13px;
}

.btn_xs {
	padding: 5px 8px;
	font-size: 13px;
}

.btn_s {
	padding: 6px 10px;
	font-size: 14px;
}

.btn_m {
	padding: 8px 10px;
}

.btn_l {
	padding: 12px 14px;
}

.btn_xl {
	padding: 16px 20px;
	font-size: 16px;
}

.btn_xxl {
	padding: 18px 28px;
	font-size: 18px;
}

.btn_w_l {
	padding-left: 14px;
	padding-right: 14px;
}

.btn_w_xs {
	padding-left: 8px;
	padding-right: 8px;
}

.btn_w_xxs {
	padding-left: 6px;
	padding-right: 6px;
}

/* Sub btn */
.btn_sub {
	padding-left: 2px;
	padding-right: 2px;
}

.btn_sub .fas,
.btn_sub .fa {
	font-size: 12px;
	margin: 0 2px;
}

/* Font size */
.btn_fs_l {
	font-size: 16px;
}

/* Colors */
.btn_primary,
.btn_outline_primary.active {
	color: #FFFFFF;
	background: #006fff;
}

.btn_primary:hover,
.btn_primary:focus,
.btn_outline_primary.active:hover,
.btn_outline_primary.active:focus {
	background: #0056C6;
}

.btn_secondary {
	color: #9E9E9E;
	background: #F6F7F8;
}

.btn_secondary:hover,
.btn_secondary:focus {
	color: #7E7E7E;
	background: #E6E7E8;
}

.btn_grey {
	color: #fff;
	background: #9E9E9E;
}

.btn_grey:hover,
.btn_grey:focus {
	color: #fff;
	background: #8E8E8E;
}

.btn_alert {
	color: #FFFFFF;
	background: #eb6161;
}

.btn_alert:hover,
.btn_alert:focus {
	background: #F07878;
}

.btn_warning {
	color: #fff;
	background: #FFC618;
}

.btn_warning:hover,
.btn_warning:focus {
	background: #FFD863;
}

.btn_success {
	color: #fff;
	background: #5CB85C;
}

.btn_success:hover,
.btn_success:focus {
	background: #76CC6C;
}

.btn_blue {
	color: #fff;
	background: #4257F0;
}

.btn_blue:hover,
.btn_blue:focus {
	background: #2D41D5;
}

.btn_primary.btn_disabled,
.btn_alert.btn_disabled,
.btn_warning.btn_disabled,
.btn_success.btn_disabled,
.btn_blue.btn_disabled,
.btn_primary.btn_disabled:hover,
.btn_alert.btn_disabled:hover,
.btn_warning.btn_disabled:hover,
.btn_success.btn_disabled:hover,
.btn_blue.btn_disabled:hover {
	background: #9c9c9c;
}

/* Outline */
.btn_outline_primary,
.btn_outline_secondary.active {
	color: #006fff;
	border-color: #006fff;
	background: none;
}

.btn_outline_primary.active,
.btn_outline_secondary.active {
	color: #006fff;
	border-color: #006fff;
}

.btn_outline_primary:hover,
.btn_outline_secondary.active,
.btn_outline_secondary.active:hover {
	background-color: #006fff11;
}

.btn_outline_primary:active,
.btn_outline_secondary.active:active {
	background-color: #006fff22;
}

.btn_outline_secondary {
	color: #5D637E;
	border-color: #CAD0DB;
	background: none;
}

.btn_outline_secondary:hover {
	background-color: #CAD0DB11;
}

.btn_outline_secondary:active {
	background-color: #CAD0DB22;
}

.btn_outline_secondary.btn_disabled,
.btn_outline_secondary.btn_disabled:hover,
.btn_outline_secondary.btn_disabled:active {
	color: #CAD0DB;
	border-color: #DFE3EA;
	background: none;
}

.btn_outline_grey {
	color: #9E9E9E;
	border-color: #9E9E9E;
	background: none;
}

.btn_outline_grey:hover {
	background-color: #9E9E9E11;
}

.btn_outline_grey:active {
	background-color: #9E9E9E22;
}

.btn_outline_alert {
	color: #eb6161;
	border-color: #eb6161;
	background: none;
}

.btn_outline_alert:hover {
	background-color: #eb616111;
}

.btn_outline_alert:active {
	background-color: #eb616122;
}

.btn_outline_warning {
	color: #FFC618;
	border-color: #FFC618;
	background: none;
}

.btn_outline_warning:hover {
	background-color: #FFC61811;
}

.btn_outline_warning:active {
	background-color: #FFC61822;
}

.btn_outline_success {
	color: #5CB85C;
	border-color: #5CB85C;
	background: none;
}

.btn_outline_success:hover {
	background-color: #5CB85C11;
}

.btn_outline_success:active {
	background-color: #5CB85C22;
}

/* GHOST */
.btn_ghost_primary {
	color: #006fff;
	background: none;
	border-color: transparent;
}

.btn_ghost_primary:hover {
	background-color: #CAD0DB11;
}

.btn_ghost_primary:active {
	background-color: #CAD0DB22;
}

.btn_ghost_primary.btn_disabled {
	color: #5D637E44;
}

.btn_ghost_secondary {
	color: #5D637E;
	background: none;
	border-color: transparent;
}

.btn_ghost_secondary:hover {
	background-color: #CAD0DB11;
}

.btn_ghost_secondary:active {
	background-color: #CAD0DB22;
}

.btn_ghost_secondary.btn_disabled {
	color: #5D637E44;
}

.btn_ghost_black {
	color: var(--grey-0);
	background: none;
	border-color: transparent;
}

.btn_ghost_black:hover {
	background-color: #CAD0DB11;
}

.btn_ghost_black:active {
	background-color: #CAD0DB22;
}

.btn_ghost_black.btn_disabled {
	color: #5D637E44;
}

.btn_ghost_black.btn_reset_margin {
	left: -10px;
}


.btnUi.has_arrow {
	text-align: left;
}

/* ICON BUTTON */
.icon_btn {
	display: inline-block;
	vertical-align: middle;
	padding: 4px;
	font-size: 20px;
	color: #8a8ea5;
	cursor: pointer;
	margin: 0 4px;
	transition: all 200ms;
	border-radius: 40px;
}

.icon_btn:focus,
.icon_btn:hover {
	color: #006fff;
}

.icon_btn_color {
	color: #006fff;
}

.icon_btn_white {
	color: #fff;
}

.icon_btn_black {
	color: #000;
	opacity: .65;
}

.icon_btn_black:hover,
.icon_btn_black:focus {
	color: #000;
	opacity: 1;
}

.icon_btn.m {
	font-size: 17px;
}

.icon_btn.s {
	font-size: 16px;
	margin: 0 2px;
}