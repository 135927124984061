.menu-selection-campaign-container {
    width: 310px;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 2px 12px;
    text-align: left;
    font-size: 14px;
    z-index: 1;
    line-height: 17px;
}

.menu-selection-campaign-container h3 {
    margin: 10px auto 5px auto;
    font-weight: 600;
    color: #151E39;
}

.menu-selection-campaign-container ul {
    display: flex;
    width: 95%;
    margin: 15px auto;
}
.infos-campaign {
    padding: 12px 0px;
    border-bottom: 1px solid #eee;
}
.infos-campaign:last-child {
    border-bottom: none;
}
h3.infos-title {
    margin: 0px 0px 7px;
}
.infos-campaign.flex h3.infos-title {
    margin: 0px;
}
.header-infos-campaign {

}

.menu-selection-campaign-container p {
    color:#424B5C;
    font-weight: 400;
}

.operation-name {
    color: #006FFF;
    cursor: pointer;
}

.campaign-id {
    font-size: 12px;
}

.trash-icon {
    cursor: pointer;
}

.trash-icon i {
    float: right;
}

.trash-icon i:hover {
    color: #006fff;
}

.select-campaign {
    font-weight: bold;
    margin-bottom: 10px;
}

.center-div-action {
    font-weight: bold;
    margin: auto;
}

.orange_warning {
    color: orange;
    margin-right: 10px;
}

.red_warning {
    color: red;
    margin-right: 10px;
}

.align-item-end {
    align-items: flex-end;
}

.display-none {
    display: none;
}


.ip_address_table {
    max-width: 750px;
}

.ip_address_table .table-row {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr;
    column-gap: 10px;
    align-items: center;
}

.ip_address_table .table-header {
    position: sticky;
    top: 0;
    border-radius: 5px;
    background: #F6F7F8;
    padding: 10px 15px;
    font-weight: 700;
}

.ip_address_table .table-header button {
    transition: all .2s ease-in-out;
}

.ip_address_table .table-header button:hover {
    color: #006fff;
}

.ip_address_table .table-body {
    border-bottom: 1px solid #F6F7F8;
    padding: 6px 15px;
}

.current-ip {
    display: flex;
    margin: -20px 0 30px;
}

.current-ip p {
    font-weight: 400;
    margin-right: 5px;
}

.current-ip button {
    background: #F6F7F8;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    padding: 5px 5px 5px 10px;
    transition: all .2s ease-in-out;
}

.current-ip button i {
    font-size: 11px;
}

.current-ip button:hover {
    background: #006fff;
    color: #FFF;
}