:root{
	--black: #151E39;
	--grey-0: #424b5c;
	--grey-1: #5d606f; /* Old : #5D637E; */
	--grey-2: #6e7284;
	--grey-3: #8a8ea5;
	--grey-4: #acafc0;
	--grey-5: #CACCD8;

	--turquoise: #006fff;
	--turquoise-light: #EDF4FD;
	--turquoise-dark: #0056C6;
	
	--blue: #4257F0;
	--red: #ea4545;
	--red-light: #FFE6E6;
	--yellow: #FFD41F;

	--input-bg: #F8F9FB;
	--input-border: solid 1px #C5CDDD;
	--input-border-hover: solid 1px #ACAFC0;
	--input-border-focus: solid 1px #006fff;
	--input-border-error: solid 1px var(--red);
	--input-radius: 5px;

	--main-border-color: #E9EAF0;
    --main-h-padding: 22px;
}

