/* Spinner */

.spinner {
    margin: 0 auto;
    width: 50px;
    height: 60px;
    padding-top: 20px;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner.spinner_overlayed {
    position: fixed;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 220px);
    left: 220px;
}

.spinner>div {
    background-color: #006fff;
    height: 100%;
    width: 6px;
    margin: 0 1px;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    -moz-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    -o-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    -moz-animation-delay: -1.1s;
    -o-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    -moz-animation-delay: -1.0s;
    -o-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    -moz-animation-delay: -0.9s;
    -o-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    -moz-animation-delay: -0.8s;
    -o-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.spinner_product .spinner_msg {
    text-align: center;
    font-weight: bold;
    font-size: 23px;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4)
    }
    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@-moz-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -moz-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -moz-transform: scaleY(1.0);
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

@-o-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -o-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -o-transform: scaleY(1.0);
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -moz-transform: scaleY(0.4);
        -o-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -moz-transform: scaleY(1.0);
        -o-transform: scaleY(1.0);
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

.spinner_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .4);
}

.spinner_overlay .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    padding-top: 0;
    margin-left: -25px;
    margin-top: -20px;
}