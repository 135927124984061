.card {
    box-shadow: 2px 5px 20px -10px rgba(0, 0, 0, 0.75);
}

/* ////////////////////////  BTN BESPOKE ////////////////////////// */

.buttonEdition {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 49px 0px rgba(220, 220, 220, 0.9);
}

.btn-menu {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 16px;
    line-height: 1.125;
    background: #293133;
    border-bottom: 1px solid #9E9E9E;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.btn-menu:hover {
    background: #006fff;
    transition: none;
}

.btn-menu:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
}

.disabledbtn-menuEdit {
    color: rgb(231, 234, 255);
    background-color: #0808089c;
    cursor: not-allowed;
}

.savebtn-menuEdit {
    width: 52px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(231, 234, 255);
    background-color: #0808089c;
    font-size: 16px;
    line-height: 1.125;
    border-radius: 9px;
    padding: 0px 0px;
    margin: 5px;
    cursor: pointer;
    border: 2px solid silver;
    text-decoration: none;
    cursor: pointer;
}

.savebtn-menuEdit:hover {
    text-decoration: none;
    color: #000000;
    background: #e9ecef;
    border: 1px solid rgba(0, 123, 255, 0.25);
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
    font-weight: 600;
    box-shadow: 0px 0px 2px rgba(0, 123, 255, 0.493);
}

.test:hover {
    color: blue
}

.contentOfLanguage {
    background-color: #000000;
}
/* //////////////////////////////////////////// onglets */

.countBtn {
    display: flex;
}

.onglets {
    min-width: 190px;
    height: 40px;
    color: #CACCD8;
    background-color: #101012;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    border-bottom: none;
    margin-top: 18px;
    margin-right: 5px;
    padding-right: 30px;
    padding-left: 13px;
    border-top: 2px solid #4A4B50;
    font-size: 13px;
    position: relative;
    text-align: left;
}
.onglets small{
    font-size: 11px;
    font-weight: 400;
    margin-left: 3px;
}

.angularOnglet {
    /* margin-left: 15%; */
}

.activeOnglet {
    background-color: #202124;
    color: #FFFFFF;
}
.activeOnglet.ongletHtml,
.activeOnglet.ongletWys {
    border-color: #FF3C41;
}
.activeOnglet.ongletCss {
    border-color: #3E95CF;
}
.activeOnglet.ongletJs {
    border-color: #F7DA13;
}
.activeOnglet.ongletConfig {
    border-color: #865fff;
}
.descriptionCard {
    height: 30px;
    overflow: auto;
}

.ideContainer {
    overflow: hidden;
}

.errorsBespoke {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    overflow: auto;
}

.errors {
    background: #D76666;
    text-align: left;
    padding: 14px;
    line-height: 1.4;
    color: #FFF;
}

.errors-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
}

.errors-close:hover {
    transform: rotateZ(-90deg);
}

.errors p {}

.errors p strong {
    font-weight: 700;
}

.numberErrors {
    font-size: 12px;
    display: inline-block;
    margin-left: 3px;
    font-weight: 700;
}

.none {
    display: none;
}

.editorMessage {
    position: fixed;
    z-index: 2;
    right: 20px;
    bottom: 20px;
    padding: 10px 12px;
    color: #fff;
    border-radius: 4px;
}
.editorMessageSuccess {
    background-color: #1CAA4D;
}
.editorMessageError {
    background-color: #ea4545;
}
