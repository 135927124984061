.devicesContainer {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #DEDEDE;
    height: 42px;
}

.devicesContainer div {
    margin-left: 15px;
    color: #6C757D;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
}

.isDeviceSelected p {
    color: #006fff;
    font-weight: bold;
}

.isDeviceSelected {
    border-bottom: 3px solid #006fff;
}