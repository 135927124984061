.panel_inner {
    /* Padding horizontal des sections */
    --section-padding-left: 30px;
}

.panel_inner.is_invisible {
    opacity: 0;
    pointer-events: none;
}

.panel_overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .4);
}

.panel_inner {
    position: fixed;
    z-index: 10000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500px;
    max-width: calc(100% - 20px);
    background: #fff;
    box-shadow: rgba(0, 0, 0, .2) 0 0 20px;
    outline: none;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
}

.panel_inner.left {
    left: 0;
    right: auto;
}

.panel_header,
.panel_footer {
    -ms-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    flex: 0 0 auto;
}

.panel_header {
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
}

.panel_body {
    padding: 0 30px;
    overflow-y: auto;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    flex: 1 1 auto;
}

.panel_body::before,
.panel_body::after {
    content: "";
    display: block;
    margin-bottom: 24px;
}

.panel_footer {
    padding: 0 30px 30px;
    position: absolute;
    bottom: 0;
}

.panel_footer_center {
    text-align: center;
}

.panel_footer_right {
    right: 0;
}

.panel_overlay.anim_show {
    animation: panel_overlay_show 300ms ease-out;
}

.panel_overlay.anim_hide {
    animation: panel_overlay_hide 300ms ease-out;
}

.panel_inner.anim_show {
    animation: panel_right_show 300ms ease-out;
}

.panel_inner.anim_hide {
    animation: panel_right_hide 300ms ease-out;
}

.panel_inner.anim_show.left {
    animation: panel_left_show 300ms ease-out;
}

.panel_inner.anim_hide.left {
    animation: panel_left_hide 300ms ease-out;
}

@keyframes panel_overlay_show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes panel_overlay_hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes panel_right_show {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes panel_right_hide {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(50%);
    }
}

@keyframes panel_left_show {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes panel_left_hide {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%);
    }
}

/* Panel close */
.panel_close {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    overflow: hidden;
}

.panel_close::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    background: url(../../Assets//common.svg) 0 -16px;
}

/* Panel side */
.panel_side {
    position: fixed;
    top: 0;
    bottom: 0;
    background: #F5F6F8;
    animation: panel_side_left_show 300ms ease-out;
}

.panel_inner.left .panel_side {
    right: 0;
}

@keyframes panel_side_left_show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Content */
.panel_title {
    font-size: 20px;
    font-weight: 500;
}