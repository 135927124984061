.containerConfirmModal {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.contenuModal {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    width: 650px;
    padding-bottom: 30px;
    background: #FFFFFF;
    border-radius: 5px;
    border-top: 10px solid #D76666;
    text-align: center;
}
.btn-closeModal {}
.confirmModalTitle{
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    padding: 0 30px;
    margin: 30px 0 20px;
}
.confirmModalSection{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 0 30px;
}
.confirmModalSection label{
    min-width: 150px;
    text-align: left;
}
.confirmModalSection label input{
    text-align: left;
    width: 100%;
}
.publicationPropertiesContainer{}
.publicationPropertiesInner{
    max-height: 300px;
    overflow-y: scroll;
}
.publicationProperty{
    padding: 0 30px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.publicationProperty p{
    min-width: 200px;
    text-align: left;
    font-size: 14px;
}
.publicationProperty input{
    font-size: 14px;
}

.btnGroup {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnCharger,
.btnGo {
    background: #D76666;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: #FFF;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.btnCharger > div,
.btnGo div{
    margin-right: 10px;
}
.btnGo {
    background: rgb(50, 211, 10);
    margin-left: 20px;
}
.btnCharger:hover {
    background: #b65454;
}
.btnGo:hover {
    color: #FFF;
    background: #2aaa0b;
}

.listePublication {
    height: 450px;
    overflow: auto;
}

#alertSuccessPublish {
    display: none;
    width: 50%;
    text-align: center;
    position: absolute;
    margin: auto;
    z-index: 2000;
}

#alertBadPublish {
    display: none;
    width: 50%;
    text-align: center;
    position: absolute;
    margin: auto;
    z-index: 2000;
}