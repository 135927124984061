.container_editor_account_api {
    display: block;
    width: 90%;
    margin: auto;
}

.form_create_script form {
    display: flex;
    flex-direction: column;
    margin: 25px auto;
    width: 450px;
    height: auto;
    min-height: 160px;
    box-shadow: 0px 0px 20px #0000001A;
    padding: 15px;
    border-radius: 5px;
}

.form_create_script form input {
    margin: 10px auto;
}

.container_btn_create_script {
    display: flex;
    justify-content: space-evenly;
}

.suffix_input div {
    display: flex;
    justify-content: flex-start;
}

.suffix_input div::before {
    content: 'BeyableJs-';
    color: black;
    display: block;
    width: 75px;
    line-height: 60px;
}

.suffix_input div::after {
    content: '.js';
    color: black;
    display: block;
    width: 35px;
    line-height: 60px;
}

.input-text_create_script {
    outline: none !important;
    padding: 10px !important;
    border: 1px solid #DEDEDE;
    width: 283px;
}

.input-text_create_script:focus {
    border: 1px solid rgb(103, 214, 215);
}