.screenShotContainer {
    padding: 15px;
    margin-left: 15px;
    width: 50%;
}

.screenshotUploadContainer {
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 50px;
}

.screenshotUploadContainer input[type=file] {
    left: 236px;
    top: 0;
    opacity: 0;
    position: absolute;
    font-size: 90px;
    width: 120px;
    height: 45px;
    cursor: pointer;
}

.screenshot-title {
    display: flex;
    justify-content: space-between;
    width: 350px;
    text-align: right;
    border-bottom: 1px solid #CCCCCC;
}

.screenshot-title h2 {
    font-size: 18px;
    color: #000000;
}

.screenshot-upload {
    cursor: pointer;
    width: 100px;
    height: 30px;
    color: #006fff;
    font-size: 16px;
    font-weight: bold;
}

.screContainer img {
    border: 1px solid #CCCCCC;
}

.screenShotContainer form {
    display: flex;
    width: 100%;
    margin: 0px auto 15px auto;
}

.input-text-screenshot {
    outline: none !important;
    padding: 10px !important;
    border: 1px solid #DEDEDE;
    width: 80px;
}

.screenshot-width-screen {
    margin-top: 11px;
    color: #999999;
}

.customizePreviewInner {
    position: relative;
    width: 500px;
}

.customizePreviewImg {
    display: block;
    width: 100%;
    opacity: .7;
}

.customizePreview {
    display: inline-block;
    box-shadow: rgba(0, 0, 0, .1) 0 0 20px;
    border: solid 1px #ddd;
    overflow: auto;
    max-height: 88vh;
}

.customizeZone {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: rgba(103, 214, 215, .88);
    transition: all 100ms;
}

.customizeZone::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: solid 4px transparent;
    transition: all 100ms;
}

.customizeZone:hover::after {
    border-color: rgba(0, 0, 0, .15);
}

.customizeZone img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.customizeZone-name {
    color: honeydew;
    font-weight: bold;
    margin: auto;
    font-size: 21px;
}

.refresh-btn i {
    color: #006fff;
    font-size: 20px;
    position: relative;
    left: 35px;
    top: 11px;
    cursor: pointer;
}

.refresh-btn i:hover {
    color: #79e2e4;
}