.navBar {
    background: #FFFFFF;
    color: #424B5C;
    height: 56px;
    padding: 0 20px;
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
    border-bottom: solid 1px #E9EAF0;
}

.logo {
    width: 27.24px;
    margin-right: 12px;
}

.brand {
    color: #006FFF;
    font-size: 21px;
    font-weight: 700;
    margin-right: 58px;
}

.backBlue {
    background-color: #adabab;
}

.listCreation {
    display: block;
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 10000;
    border-radius: 0px 0px 15px 15px;
    background: #212024;
    text-align: center;
    width: 180px;
    height: 70px;
    box-shadow: 0px 3px 7px rgb(158 158 158);
}

.listCreation nav div {
    margin: 10px auto;
    padding: 0px;
}

.listCreation nav div .lienNav {
    padding: 0px;
    font-weight: bold;
}