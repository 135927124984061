.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionNotFound {
    height: 250px;
}


.p404 {
    font-size: 205px;
    font-weight: bold;
}

.titleNotFound {
    margin: 5px;
    margin-bottom: 60px;
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: black;
}