.modal_content {
    font-size: 15px;
}
.containerIP {
    margin-top: 3px;
}

.containerIP button {
    background: #cfcccc;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    padding: 5px 5px 5px 10px;
    transition: all .2s ease-in-out;
}

.containerIP button:hover {
    background: #006fff;
    color: #FFF;
}
.ip_input {
    background: #fff;
    border: solid 1px #C5CDDD;
    border-radius: 5px;
    color: #424b5c;
    transition: all 200ms ease-in-out;
    padding: 8px 8px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    outline: none;
}
.ip_input:focus {
    border: solid 1px #006fff;
    box-shadow: inset 0 0 0 1px #006fff, 0 0 0 3px #EDF4FD;
}
.ip_button {
    border: none;
    width: fit-content;
    margin-top: 5px;
    margin-bottom: 5px;
}
.messageInfos {
    position: relative;
    margin-bottom: 16px;
    background-color: #f6f7f8;
    padding: 10px 16px;
    border-radius: 6px;
    display: flex;
    align-items: baseline;
    color: #757b96;
    text-align: left;
}

.infoLabel {
    font-weight: 600;
    flex: 0 0 auto;
    margin-right: 10px;
}
.starting_options_action {
    border: unset;
    background-color: unset;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #006fff;
    cursor: pointer;
}
.starting_options_icon {
    display: inline-block;
    padding: 7px 7px;
    color: #9E9E9E;
    cursor: pointer;
    font-size: 16px;
    margin: -7px 0;
    outline: none;
}
.starting_options_icon:focus {
    outline: none;
}
.ip_address_table {
    max-width: 750px;
}
.table_body {
    border-bottom: 1px solid #F6F7F8;
    padding: 6px 15px;
}
.table_row {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr;
    column-gap: 10px;
    align-items: center;
}
.editing {
    align-items: flex-start;
}