.title-list-simplify {
    margin-top: 30px;
    margin-bottom: 30px;
}

.simplify-item-env {
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 7px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
}

.list-account-simplify {
    font-family: "IBM Plex Sans", "Roboto", Arial, sans-serif;
    display: flex;
    max-width: 900px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
}

.simplify-item {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px rgba(220, 220, 220, 0.9);
    background: #FFF;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    position: relative;
    height: 110px;
}

.simplify-item-btn {}

.simplify-item-btn button {
    border: none;
    color: #9e9e9e;
    border: 1px solid #9e9e9e;
    border-radius: 3px;
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 600;
    background: #f6f7f8;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.simplify-item-btn button a {}

.simplify-item-btn .edit-simplify:hover, .simplify-item-btn .edit-simplify:hover a {
    background: #006fff;
    border-color: #006fff;
    color: #FFF;
}

.simplify-item-btn .delete-simplify:hover {
    background: #D76666;
    border-color: #D76666;
    color: #FFF;
}

.simplify-name {
    font-weight: bold;
    text-align: left;
    width: 110px;
}

.simplify-name:hover {
    color: #006fff;
}

.simplify-status-account {
    font-size: 15px;
    padding: 5px 7px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
}

.domaine_list_container {
    display: flex;
}

.domaine_list_container i {
    margin-left: 10px;
}

.domaine_list_container:hover {
    color: #006fff;
    cursor: pointer;
}

.domain_list {
    text-align: left;
    width: 180px;
    height: 70px;
    overflow: auto;
}

.domain_list h2 {
    font-weight: bold;
    text-align: center;
}