.custom-file-input~.custom-file-label::after {
    content: "Choisir un Fichier";
}
.uploadLogo:hover {
    color: rgb(4, 209, 55);
    cursor: pointer;
}
.upload-field{
	margin: 0 10px;
}
.upload-dragdrop{}
.upload-open-folder{
	background: #293133;
	color: #FFF;
	border-radius: 3px;
	padding: 10px 20px;
	margin-top: 10px;
	cursor: pointer;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.upload-open-folder:hover,
.upload-result-close:hover{
	background: #006fff;
}
.upload-temp-file{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.upload-temp-file span{
	white-space: nowrap;
}
.upload-temp-file p{
	margin-left: 5px;
	font-weight: 700;
}
.upload-btn-confirm{
	padding: 10px 20px;
	border: none;
	font-weight: 700;
	width: 150px;
	margin: 20px auto;
	background: #006fff;
	color: #FFF;
	display: inherit;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.upload-btn-confirm:hover{
	background: #5abdbe;
}
.upload-result-container{
	width: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.upload-result-container img{
	max-width: 50%;
}
.upload-result-link{
	margin: 20px 0;
	font-weight: 700;
	cursor: pointer;
	text-align: center;
	background: #F6F7F8;
	color: #293133;
	padding: 10px;
	border-radius: 3px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.upload-result-link:hover{
	background: #006fff;
	color: #FFF;
}
.upload-result-close{
	background: #293133;
	color: #FFF;
	padding: 10px 20px;
	border: none;
	font-weight: 700;
	border-radius: 3px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}